
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
// import Swal from 'sweetalert2/dist/sweetalert2.js'
import * as Yup from 'yup'
import { getMedia } from '@/core/mc-admin-helpers/assets'
import { useStore } from 'vuex'
import { Actions } from '@/store/enums/StoreEnums'
import { ElNotification } from 'element-plus/es'
import { Picture as IconPicture } from '@element-plus/icons-vue'
interface ProfileDetails {
    name: string;
    surname: string;
    company: string;
    contactPhone: string;
    contactEmail: string;
    oldPassword: string;
    newPassword: string;
    passwordConfirmation: string;
}

export default defineComponent({
  name: 'AccountOverview',
  components: {
    ErrorMessage,
    IconPicture,
    Field,
    Form
  },
  setup () {
    const store = useStore()
    const avatarPath = ref('') as any
    const currentAccount = ref(computed(() => store.getters.currentAccount))
    const saveChangesButton = ref<HTMLElement | null>(null)
    const isUpdateLoading = ref(false)
    const submitForm = ref()
    // // This function also returns Promise
    // const fetchAvatar(url) =>  ({
    //
    // )
    const payloadAvatar = {} as any
    payloadAvatar.accountUuid = currentAccount.value.accountUuid
    payloadAvatar.avatarPath = currentAccount.value.profileAvatarPath

    const fetchAvatar = (url) => {
      return fetch(url).then(response => {
        // console.log(response) // Logs the response
        return response
      })
    }
    // async function fetchAvatar (url) {
    //   return fetch(url).then((response) => {
    //     return response
    //   })
    //   // })const response = await fetch(url)
    //   // console.log(response.blob())
    //   // return response
    // }
    // function blobToFile (theBlob: Blob, fileName:string): File {
    //   const b: File = theBlob
    //   // A Blob() is almost a File() - it's just missing the two properties below which we will add
    //   b.lastModifiedDate = new Date()
    //   b.name = fileName
    //   return b
    // }
    const changeAvatar = () => {
      const randomNumber = Math.floor(Math.random() * 9) + 1
      avatarPath.value = 'icons/profile-icon-' + randomNumber + '.png'
      avatarPath.value = getMedia(avatarPath.value)
      fetchAvatar(avatarPath.value).then((response) => {
        // console.log(r.blob())
        return response.blob()
      }).then((myBlob) => {
        if (myBlob instanceof Blob) {
          file.value = new File([myBlob], 'profile-icon-' + randomNumber + '.png', { lastModified: Date.now(), type: myBlob.type })
        } else {
          console.log('not instance of blob')
        }
      })
    }

    const imageUri = computed(() => avatarPath.value)
    const file = ref('') as any
    const removeAvatarAndImage = () => {
      avatarPath.value = getMedia('icons/admin-default-icon.png')
      file.value = 'default'
    }
    const onChangeFileUpload = (event) => {
      if (event.target.files.length === 0) {
        file.value = ''
        return
      }

      file.value = event.target.files[0]
    }

    watch(file, (value: any) => {
      if (!(value instanceof File)) {
        return
      }
      const fileReader = new FileReader()

      fileReader.readAsDataURL(value)
      fileReader.addEventListener('load', () => {
        avatarPath.value = fileReader.result as string
      })
    })

    const profileDetailsValidator = Yup.object().shape({
      fname: Yup.string().required().label('First name'),
      lname: Yup.string().required().label('Last name'),
      company: Yup.string().label('Company'),
      phone: Yup.string().required().label('Contact phone'),
      email: Yup.string().email().required().label('Email address'),
      oldPassword: Yup.string(),
      newPassword: Yup.string().when('oldPassword', {
        is: (val) => val !== '',
        then: Yup.string().required('New password is a required field')
      }),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    })
    const profileDetails = ref<ProfileDetails>({
      name: currentAccount.value.firstName,
      surname: currentAccount.value.lastName,
      company: currentAccount.value.company.name,
      contactPhone: currentAccount.value.phone,
      contactEmail: currentAccount.value.email,
      oldPassword: '',
      newPassword: '',
      passwordConfirmation: ''
    })
    onMounted(() => {
      store.dispatch(Actions.API_GET_AVATAR_URL, payloadAvatar).then((response) => {
        if (response.payload.image !== null) {
          avatarPath.value = response.payload.image
        } else {
          avatarPath.value = getMedia('/icons/admin-default-icon.png')
        }
      })
    })

    const isFormValid = ref(false)
    const saveChanges = () => {
      if (!isFormValid) {
        return
      }
      isUpdateLoading.value = true
      // const payload = {
      //   firstName: currentAccount.value.firstName,
      //   lastName: currentAccount.value.lastName,
      //   email: currentAccount.value.email,
      //   phone: currentAccount.value.phone
      // }
      submitForm.value.validate().then((response) => {
        if (response.valid) {
          isFormValid.value = true
          const config = {
            headers: {
              'content-type': 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2)
            }
          }
          const data = new FormData()
          if (file.value !== '') {
            data.append('image', file.value)
          }
          data.append('firstName', profileDetails.value.name)
          data.append('lastName', profileDetails.value.surname)
          data.append('email', profileDetails.value.contactEmail)
          data.append('phone', profileDetails.value.contactPhone)
          if (profileDetails.value.oldPassword !== '' || profileDetails.value.newPassword !== '' || profileDetails.value.passwordConfirmation !== '') {
            data.append('password', profileDetails.value.oldPassword)
            data.append('newPassword', profileDetails.value.newPassword)
            data.append('newPasswordConfirmation', profileDetails.value.passwordConfirmation)
          }

          // data.append('description', targetData.description)
          // data.append('duration', (totalDuration.value * 60).toString())
          // data.append('introDuration', (targetData.introDuration * 60).toString())
          // data.append('challengeDuration', (targetData.challengeDuration * 60).toString())
          // data.append('debriefingDuration', (targetData.debriefingDuration * 60).toString())
          // data.append('updatedAt', new Date().toISOString())
          // data.append('targetScore', target.value)
          // data.append('tags', JSON.stringify(usedTags.value))

          const payload = {
            data: data,
            accountUuid: currentAccount.value.accountUuid,
            config: config
          }
          console.log('enters here')
          store.dispatch(Actions.API_UPDATE_ACCOUNT, payload)
            .then(() => {
              isUpdateLoading.value = false
              ElNotification.success({
                message: 'Successfully update the account details',
                dangerouslyUseHTMLString: true,
                customClass: 'houston-notification',
                position: 'top-right',
                duration: 10000
              })
            }).catch((response) => {
              console.log('e', response)
              isUpdateLoading.value = false
              ElNotification.error({
                message: response.data.error.message,
                dangerouslyUseHTMLString: true,
                customClass: 'houston-notification',
                position: 'top-right',
                duration: 10000
              })
            })
        } else {
          isUpdateLoading.value = false
        }
      })
    }
    return {
      profileDetails,
      profileDetailsValidator,
      avatarPath,
      imageUri,
      saveChangesButton,
      isUpdateLoading,
      submitForm,
      changeAvatar,
      onChangeFileUpload,
      removeAvatarAndImage,
      getMedia,
      saveChanges
    }
  }
})
